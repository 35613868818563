import { isPlainObject, isArray, isEqual, isEmpty, cloneDeep } from "lodash-es";

// Return changes after removing fields that are empty on both, changes and original.
// Empty fields on changes only makes sense when the field exist on original, meaning
// the field is been cleared.
//
// The need for this helper arises from the use of the lodash 'set' helper, that builds
// the full path of a field the user have changed in the interface. If the user clears
// the field later we get this situation where a subtree exist in changed but not in original.
//
// Some components prune before saving to the namespace (to help with changed field yellow background)
// and we also prune at the end of extractChanges bellow.
//
const prune = (changes, original) => {
  const keys = Object.keys(changes);
  
  if (!isPlainObject(changes) || !isPlainObject(original)) {
    throw new Error(`pruneChanges expected a pair of objects, got: ${changes}, ${original}`);
  }

  keys.forEach((field) => {
    const newVal = changes[field],
          oldVal = original[field];

    if (isPlainObject(newVal)) {
      // first prune object fields
      prune(newVal, oldVal || {});
      if (isEmpty(newVal)) {
        // empty object should be removed
        delete(changes[field]);
      }
    }

    if ((isPlainObject(newVal) || isArray(newVal)) && isEmpty(newVal) && isEmpty(oldVal)) {
      // remove only fields that are empty on both objects
      delete(changes[field]);
    }
  });

  return changes;
};


export const pruneChanges = (changes, original) => {
  return prune(cloneDeep(changes), original);
};


// extractChanges iterates over 'changes' object fields and retuns an object with changed fields
// i.e fields that have a different value or do not exist in 'original'. Only changed fields are returned.
//
// This function assumes 'changes' is always a superset of 'original' (have all its keys and may have keys not in original).
// For the use case this helper is been implemented - the 'changes' method in redux_ns - this is true.
//
// OBJECT FIELDS:
// -------------
// When the field is an object itself, the same procedure will be called recursivelly on
// that object.
//
// ARRAY FIELDS:
// -------------
// Arrays fields are treated like a single value and is fully copied, except when the items in
// the array are objects. In this case, the same procedure is called for each object in the array.
// The 'id' field is used to find the equivalent object on the array of the ORIGINAL object.
//
// If an object with the same 'id' is not found in ORIGINAL, the object is considered new and is
// fully copied.
//
// The 'id' field is always copied, along with the changed, created and removed fields.
// If the object does not have an 'id' field, it is assumed it is a new object and it is fully
// copied, unless it is empty. Empty objects in an array is an new object the user added but failed
// to fill in any field and should be discarded.
//
// The resulting field will be an array where objects that are not changed will be present as a
// object with form '{id}', removed objects will not be present and new objects may or may not have
// an 'id' field. Changed objects will have 'id' and the changed fields/values.
// The order of the array is not garanteed.
//
// The components that use array of objects are InputTable and InputLinkArray and simple array is used by InputEnum,
// but nothing is stopping the business logic to set an array field of any type.
//
export const extractChanges = (changes, original) => {
  let keys;
  let result;

  if (isPlainObject(changes) && isPlainObject(original)) {
    result = {};
    // use the changes keys - assume changes have all the keys of original (is a superset of original)
    keys = Object.keys(changes);
  } else {
    throw new Error(`extractChanges expected a pair of objects, got: ${changes}, ${original}`);
  }

  keys.forEach((field) => {
    const newVal = changes[field],
          oldVal = original[field];

    // copy field if the field in change is different from the field in original
    if (!isEqual(newVal, oldVal)) {
      if (isPlainObject(newVal)) {
        // field is an object, call the function recursivelly
        let res = extractChanges(newVal, oldVal || {});
        if (!(isEmpty(res) && isEmpty(oldVal))) {
          // dont set empty tree, but needs final prune at the end
          result[field] = res;
        }
      } else if (isArray(newVal) && isPlainObject(newVal[0])) {
        // field is array of objects, call function recursivelly on each object
        let res = [];
        newVal.forEach((newObj) => {
          let oldObj;
          if (newObj.id) {
            oldObj = oldVal.find((o) => o.id === newObj.id);
          }
          if (oldObj) {
            // found equivalent object in original, call function recursivelly on it
            let x = extractChanges(newObj, oldObj || {});
            // array object must have id
            x.id = newObj.id;
            res.push(x);
          } else {
            // new object (not found in original or does not have 'id'), copy the whole object
            // unless it is empty, in whicj case we drop it.
            if (! isEmpty(newObj)) {
              res.push(newObj);
            }
          }
        });
        result[field] = res;
      } else {
        // is neither and object or array of objects, lets copy it
        result[field] = newVal;
      }
    }
  });

  // this last prune will renove empty nodes that closer to the root of the namespace
  // like a fieldset name.
  return pruneChanges(result, original); 
};

// copyFields return an object with the fields from original that exists in changes.
// iterates over 'changes' and copy the value of the field from 'original'.
// 
// OBJECT FIELDS:
// -------------
// When the field is an object itself, the same procedure will be called recursivelly on
// that object.
//
// ARRAY FIELDS:
// -------------
// Arrays fields are treated like a single value and is fully copied, except when the items in
// the array are objects. In this case, the same procedure is called for each object in the array.
// The 'id' field is used to find the equivalent object on the array of the ORIGINAL object.
//
// If an object with the same 'id' is not found in ORIGINAL, the object is considered new and
// nothing has to be done.
//
// The 'id' field is always copied, along with the fields that exist in 'changes'.
// Note that when the object exist in original, at least the id will always be copied. 
//
// If the object does not have an 'id' field, it is assumed it is a new object and nothing has
// to be done.
//
// Iterates over the array in 'original' and create objects in the form '{id}' for the removed objects
// (objects that exist in original but not in changes).
//
// The resulting field value will be an array where objects that are not changed will be present as a
// object with form '{id}', removed objects will also be present as an object with form '{id}'.
// Changed objects will have 'id' and the changed fields/values. The order of the array is not garanteed.
// 
export const copyFields = (changes, original) => {
  const keys = Object.keys(changes);
  let result;
  
  if (isPlainObject(changes) && isPlainObject(original)) {
    result = {};
  } else {
    throw new Error(`copyFields expected a pair of objects, got: ${changes}, ${original}`);
  }

  keys.forEach((field) => {
    const newVal = changes[field],
          oldVal = original[field];

    if (isPlainObject(newVal)) {
      // field is an object, call the function recursivelly
      result[field] = copyFields(newVal, oldVal || {});
    } else if (isArray(newVal) && (isPlainObject(newVal[0]) || isEmpty(newVal))) {
      // field is array of objects, call function recursivelly on each object
      // found by 'id'. Ignore new objects (not found in original or without id).
      let res = [];
      newVal.forEach((newObj) => {
        let oldObj;
        if (newObj.id) {
          oldObj = oldVal.find((o) => o.id === newObj.id);
        }
        if (oldObj) {
          // found equivalent object in original, call function recursivelly on it
          res.push(copyFields(newObj, oldObj || {}));
        }
      });
      // now lets find objects that exist in original but not in changes (removed)
      // and save their ids as {id}.
      oldVal.forEach((oldObj) => {
        let newObj = newVal.find((o) => o.id === oldObj.id) 
        if (!newObj) {
          res.push({id: oldObj.id});
        }
      });
      // finally set the field with the array built above
      result[field] = res;
    } else {
      // is neither and object or array of objects, lets copy it
      result[field] = oldVal;
    }
  });

  return result;
};
