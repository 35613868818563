import React, { createElement, useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { store } from './parati/scripts/utils/state.js';
import { getRandomIntInclusive } from './common/helpers/utils.js';

const AsyncCopa = () => {
  const [Component, setComponent] = useState(null);

  useEffect(() => {
    import("./copacabana/src/ui/loading.js")
      .then((module) => {
        setComponent(createElement(module.default));
      })
      .catch((error) => {
        console.log("Erro ao carregar componente: ", error);
      });
  }, []);

  return Component;
};

const AsyncParati = () => {
  const [Component, setComponent] = useState(null);

  useEffect(() => {
    import("./parati/parati.js")
      .then((module) => {
        setComponent(createElement(module.default));
      })
      .catch((error) => {
        console.log("Erro ao carregar componente: ", error);
      });
  }, []);

  return Component;
};

export const ProjectSwitcher = ({ defaultProject }) => {
  const [currProject, setCurrProject] = useState(defaultProject);
  const [collapsed, setCollapsed] = useState(
    window?.finanzero?.formCollapsed || false
  );

  useEffect(() => {
      window.handleProject = setCurrProject;
      window.projectCurr = currProject;
      window.formCollapse = setCollapsed;

      if(!localStorage.getItem('abTestHash')) {
          localStorage.setItem('abTestHash', getRandomIntInclusive(1, 100));
      }
  }, [])

  if (collapsed) {
    return null;
  }

  if (!("fetch" in window)) {
    import("./common/utils/polyfill.js");
  }

  if (currProject == "copacabana") {
    return <AsyncCopa />;
  }

  return (
    <Provider store={store}>
      <AsyncParati />
    </Provider>
  );
};
